// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these files to reference
// that code so it'll be compiled.

import '@enginuitygroup/library';
import 'bootstrap';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/collapse';

import '../styles/application.scss';
import '../styles/tailwind.scss';

// import "@fortawesome/fontawesome-free/js/all";
import '@fortawesome/fontawesome-free/css/all.css';
import 'object-fit-images';

import '../components/theme.js';
import '../components/close-top-header.js';
import '../components/menu-actions.js';
import '../components/cookie-policy-bar.js';

// Hub UI NG
import '../styles/fonts.scss';
import '../styles/searchbox.scss';

import "@hotwired/turbo-rails"
Turbo.start()

import '../controllers/plain';

import '../vendor/slick-carousel';

window.navState = {
  selectedLane: null,
};

const _colorForLane = (lane) => {
  const dict = {
    hoods: 'green',
    schools: 'navy',
    homes: 'blue',
    finance: 'orange',
  };

  return dict[lane];
};

const _setLaneFocus = (lane) => {
  if (!lane) {
    document.querySelector('body').scrollIntoView({ behavior: 'smooth' });

    return setTimeout(() => {
      const searchboxes = document.querySelectorAll(
        '#home-hero-searchbox [data-search-target="typeahead"]',
      );

      if (searchboxes?.length > 1) {
        searchboxes[1].focus();
      }
    }, 300);
  }

  const BUTTON_OFFSET = (() => {
    const BASE_PADDING = 50;
    const agentHeaderHeight =
      document.querySelector('[data-hq-agent-header]')?.clientHeight || 0;
    const navHeight =
      document.querySelector('[data-hq-nav]')?.clientHeight || 0;

    return BASE_PADDING + agentHeaderHeight + navHeight;
  })();

  const button = document.querySelector('#button_hoods');
  const buttonPosition =
    button.getBoundingClientRect().top + window.scrollY - BUTTON_OFFSET;

  window.scrollTo({ top: buttonPosition, behavior: 'smooth' });
};

$(document).on('turbo:load', function () {
  // Fade in the hero
  setTimeout(function () {
    $('#home-hero-header').removeClass('hqtw-opacity-0');
  }, 50);

  // Lane UX
  // TODO: Port this to a Stimulus LaneController.
  (function () {
    document
      .querySelectorAll('[data-hq-lane-toggle]')
      .forEach((element, index) => {
        const lane = element.getAttribute('data-hq-lane-toggle');

        // Staggered fade in on first load for each button
        setTimeout(
          () => {
            element.classList.remove('hqtw-opacity-0');
          },
          index * 200 + 250,
        );

        // Remove long initial transition durations on each button
        setTimeout(() => {
          element.removeAttribute('style');
        }, 1000);

        element.addEventListener('click', () => {
          // If no lane is selected, select this lane
          if (!window.navState.selectedLane) {
            window.navState.selectedLane = lane;

            // Dim other lanes
            document
              .querySelectorAll('[data-hq-lane-toggle]')
              .forEach((element) => {
                const l = element.getAttribute('data-hq-lane-toggle');

                if (l !== lane) {
                  $(element).fadeTo(100, 0.5).css('filter', 'saturate(0.5)');
                }
              });

            // Expand the sublanes for the selected lane
            $(`#lane_${lane}`).slideDown();

            // Scroll the viewport so the sublanes are visible
            _setLaneFocus(lane);

            // Flourish: add a touch of colour to the hero based on the selected lane
            document
              .querySelector('[data-hq-hero]')
              .setAttribute('color', _colorForLane(lane));

            return;
          }

          // Hide this lane as we're toggling it back off
          if (window.navState.selectedLane === lane) {
            window.navState.selectedLane = null;

            document
              .querySelectorAll('[data-hq-lane-toggle]')
              .forEach((element) => {
                $(element).fadeTo(100, 1).css('filter', 'unset');
              });

            // Collapse the sublanes
            $(`#lane_${lane}`).slideUp();

            // Go back to the default green for the hero
            document
              .querySelector('[data-hq-hero]')
              .setAttribute('color', 'green');

            // Reset viewport
            _setLaneFocus();

            return;
          }

          // Another is lane is selected.
          // Hide existing lane
          $(`#lane_${window.navState.selectedLane}`).slideUp();

          // Set this lane as selected
          window.navState.selectedLane = lane;

          // Fade other lanes, make sure this one isn't faded
          document
            .querySelectorAll('[data-hq-lane-toggle]')
            .forEach((element) => {
              const l = element.getAttribute('data-hq-lane-toggle');

              if (l !== lane) {
                $(element).fadeTo(100, 0.5).css('filter', 'saturate(0.5)');
              } else {
                $(element).fadeTo(100, 1).css('filter', 'unset');
              }
            });

          // Show this lane
          $(`#lane_${window.navState.selectedLane}`).slideDown();

          // Scroll the viewport so the sublanes are visible
          _setLaneFocus(lane);

          document
            .querySelector('[data-hq-hero]')
            .setAttribute('color', _colorForLane(lane));

          return;
        });
      });
  })();

  _setLaneFocus();

  // Handler for opening the mobile nav
  $('#navbarCollapse').on('shown.bs.collapse', function (event) {
    $('#hqBrandSearch').css('flex-direction', 'column').css('margin', '1rem');
  });

  // Handler for closing mobile nav
  $('#navbarCollapse').on('hidden.bs.collapse', function (event) {
    $('#hqBrandSearch').css('flex-direction', 'row').css('margin', '0');
  });

  $('[data-hq-meet-experts]').on('click', () => {
    document
      .querySelector('[data-hq-meet-experts]')
      .scrollIntoView({ behavior: 'smooth' });
  });

  // TODO: Port this to a Stimulus SliderController.
  const slider = $('.hq-premium-slider');

  if (slider && slider.length) {
    slider.slick({
      centerMode: true,
      centerPadding: '25%',
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
      focusOnSelect: true
    });
  }
});
